<template>
  <div
    class="sigma_subheader style-11 bg-cover bg-center"
    :style="{ backgroundImage: `url(${topImg})` }"
  >
    <div class="container">
      <div class="sigma_subheader-inner">
        <h1>{{ this.routeName }}</h1>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="btn-link" to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.routeName }}
        </li>
      </ol>
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import topImg from "../../assets/img/top.jpg";
export default {
  data() {
    return {
      topImg,
      routeName: "",
    };
  },
  mounted() {
    this.routeName = this.$route.name;
    // console.log("this is router", this.routeName)
  },
};
</script>

<style>
.sigma_subheader .breadcrumb li a {
  color: var(--white-color);
  text-decoration: none;
}
</style>
